const commonDict = require('./common');

class ExportObject {
  constructor(reports) {
    this.reports = reports;
    this.exports = [];
  }
  distribute(report) {
    const response = {};
    response.common = {
      _id: report._id,
      subject: report.classInfo.subject + (report.classInfo.correspondence ? '（通信課程）' : ''),
      grade: report.classInfo.grade,
      personCount: report.classInfo.personCount,
      responsibleYear: report.user.institution.responsibleTerm.year,
      responsibleMonth: report.user.institution.responsibleTerm.month,
      institutionId: report.user.institution._id,
      schoolType: report.user.institution.schoolType,
    };

    response.mediaCategories = [];
    if (['(ダンス等)既存の振り付け', '(演劇等)既存の台本'].includes(report.media.categories[0])) {
      const categoryName = report.media.categories.shift();
      response.mediaCategories.push([categoryName, '(5)入手・掲載元の参考情報']);
    }
    for (let i = 0; i < report.media.categories.length; i++) {
      const categoryName = this.cutCategoryHeadNumber(report.media.categories[i]);
      const categoryExportColumn = report.media.categoriesExportColumn[i];
      response.mediaCategories.push([categoryName, categoryExportColumn]);
    }

    response.mediaInfo = report.media.info;

    response.workCategory = this.cutCategoryHeadNumber(report.work.subCategory);

    response.workInfo = report.work.info;

    response.layer5Dict = report.media.regulation.exportFormat;

    response.layer6Dict = report.work.regulation.exportFormat;

    return response;
  }

  usefullSingleConverter(object, key, value) {
    // 既にキーがあればカンマ区切りで追記、なければ新規作成
    if (key == 'null' || !value) return;
    if (object[key]) {
      object[key] += `, ${String(value)}`;
    } else {
      object[key] = String(value);
    }
  }

  usefullObjectConverter(target, object) {
    // 既にキーがあればカンマ区切りで追記、なければ新規作成
    for (const key in object) {
      if (target[key]) {
        target[key] += `, ${String(object[key])}`;
      } else {
        target[key] = String(object[key]);
      }
    }
  }

  infoFormatConverter(value, formatPattern, format) {
    switch (formatPattern) {
      case '文字列の付加':
        return format.replace('{{value}}', value);
      case '値により分岐':
        const formatDict = {};
        const patterns = format.split('\n');
        for (const pattern of patterns) {
          const [key, value] = pattern.split('=');
          formatDict[key] = value;
        }
        return formatDict[value] == 'null' ? null : formatDict[value];
      default:
        return value;
    }
  }

  cutCategoryHeadNumber(category) {
    if (category.match(/^\d{2}/)) {
      return category.substr(2);
    }
    return category;
  }

  convertCommon(distributedObject) {
    const response = {};
    const { common } = distributedObject;
    const convertDict = commonDict;
    for (const key in common) this.usefullSingleConverter(response, convertDict[key], common[key]);
    return response;
  }

  convertMediaCategories(distributedObject) {
    const response = {};
    const { mediaCategories } = distributedObject;
    for (const [value, columnName] of mediaCategories) {
      this.usefullSingleConverter(response, columnName, value);
    }
    return response;
  }

  convertMediaInfo(distributedObject) {
    const response = {};
    const { mediaInfo, layer5Dict } = distributedObject;
    for (const key in mediaInfo) {
      if (!layer5Dict[key]) throw new Error(`未振り分けの5層項目です。キー=${key}, ID=${distributedObject.common._id}`);
      const format = layer5Dict[key]['フォーマット'];
      const formatPattern = layer5Dict[key]['フォーマットパターン'];
      const value = this.infoFormatConverter(mediaInfo[key], formatPattern, format);
      if (value === '入力項目なし') continue;
      this.usefullSingleConverter(response, layer5Dict[key]['エクスポートカラム'], value);
    }
    return response;
  }

  convertWorkCategory(distributedObject) {
    const response = {};
    const { workCategory } = distributedObject;
    this.usefullSingleConverter(response, '(13)著作物の分類', workCategory);
    return response;
  }

  convertWorkInfo(distributedObject) {
    const response = {};
    const { workInfo, layer6Dict } = distributedObject;
    for (const key in workInfo) {
      if (!layer6Dict[key]) throw new Error(`未振り分けの6層項目です。キー=${key}, ID=${distributedObject.common._id}`);
      const format = layer6Dict[key]['フォーマット'];
      const formatPattern = layer6Dict[key]['フォーマットパターン'];
      const value = this.infoFormatConverter(workInfo[key], formatPattern, format);
      if (value === '入力項目なし') continue;
      this.usefullSingleConverter(response, layer6Dict[key]['エクスポートカラム'], value);
    }
    return response;
  }
  convert() {
    for (const report of this.reports) {
      try {
        const distributedReport = this.distribute(report);
        const convertedReport = {
          利用報告ID: '',
          報告対象年: '',
          報告対象月: '',
          教育機関ID: '',
          '(1)教育機関種別': '',
          学年: '',
          授業名: '',
          '(2)履修者人数': '',
          '(3)入手・掲載元の分類': '',
          '(4)入手・掲載元の詳細情報': '',
          '(5)入手・掲載元の参考情報': '',
          '(6)入手・掲載元の固有コード等(ISBN、教科書番号、レコード商品番号等）': '',
          '(7)入手・掲載元の名称': '',
          '(8)著者・翻訳者・作者、制作者等': '',
          '(9)制作・発行・放送元': '',
          '(10)発行・発売時期・放送年月日': '',
          '(11)URL等': '',
          '(12)入手・掲載元の備考': '',
          '(13)著作物の分類': '',
          '(14)著作物の名称、タイトル、見出し、概要等': '',
          '(15)利用箇所・分量': '',
          '(16)著作物の備考': '',
          '(17)添付画像URL': '',
        };
        this.usefullObjectConverter(convertedReport, this.convertCommon(distributedReport));
        this.usefullObjectConverter(convertedReport, this.convertMediaCategories(distributedReport));
        this.usefullObjectConverter(convertedReport, this.convertMediaInfo(distributedReport));
        this.usefullObjectConverter(convertedReport, this.convertWorkCategory(distributedReport));
        this.usefullObjectConverter(convertedReport, this.convertWorkInfo(distributedReport));
        this.exports.push(convertedReport);
      } catch (e) {
        console.log(report);
        console.log(e.message);
        throw e;
      }
    }
  }
}

export default ExportObject;
