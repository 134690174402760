import { Form } from 'react-bootstrap';
import Autosuggest from 'react-autosuggest';
import { useErrorBoundary } from 'react-error-boundary';
import Axios from 'axios';

interface Props {
  textSymbolList: string[];
  setTextSymbolList: (value: string[]) => void;
  textSymbol: string;
  setTextSymbol: (value: string) => void;
  api: any;
  setErrorMessage: (value: string) => void;
}

const TextSymbolBox: React.FC<Props> = ({ textSymbolList, setTextSymbolList, textSymbol, setTextSymbol, api, setErrorMessage }) => {
  const { showBoundary } = useErrorBoundary();

  const onSuggestionsFetchRequested = async ({ value }) => {
    try {
      setErrorMessage('');
      const data = await api.getTextSymbol(value);
      setTextSymbolList(data);
    } catch (e: any) {
      if (!Axios.isAxiosError(e)) showBoundary(e);
      showBoundary(e.response);
    }
  };

  const inputProps = {
    placeholder: '例)数Ⅰ',
    value: textSymbol,
    onChange: (event, { newValue }) => setTextSymbol(newValue),
  };

  return (
    <Form.Group>
      <Form.Label htmlFor="textSymbol_id">教科書記号</Form.Label>
      <Autosuggest
        suggestions={textSymbolList}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={() => setTextSymbolList([])}
        getSuggestionValue={(suggestion: string) => suggestion}
        renderSuggestion={(suggestion: string) => (
          // eslint-disable-next-line
          <a className="dropdown-item" href="#">
            {suggestion}
          </a>
        )}
        inputProps={inputProps}
        theme={{
          container: 'autosuggest',
          input: 'form-control',
          suggestionsContainer: 'dropdown open',
          suggestionsList: `dropdown-menu ${textSymbolList.length ? 'show' : ''}`,
          suggestion: '',
          suggestionFocused: 'active',
        }}
      />
    </Form.Group>
  );
};

export default TextSymbolBox;
