import { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Form, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useToggle } from 'react-use';
import api from '../api/CallManagerApi';
import { TableColumn } from 'react-data-table-component';
import { useErrorBoundary } from 'react-error-boundary';
import { toast } from 'react-toastify';
import { toastConfig, toastNotAutoCloseConfig } from '../utils';
import { startLoading, stopLoading } from '../../store/loadingReducer';
import GridTable from '../components/GridTable';
import CreateUsersModal from '../components/modal/CreateUsersModal';
import { User } from '../interface/User';
import { FaPlus } from 'react-icons/fa';
import Axios from 'axios';

const UserManagement: React.FunctionComponent = () => {
  const [displayUsers, setDisplayUsers] = useState<User[]>([]);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [isDisplayCreateUserModal, setIsDisplayCreateUserModal] = useState(false);
  const [isDisplayDeleteUserModal, setIsDisplayDeleteUserModal] = useState(false);
  const [isDisplayDeletePasswordModal, setIsDisplayDeletePasswordModal] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [toggleCleared, setToggleCleared] = useToggle(false);
  const [disabledBlukButton, setDisabledBlukButton] = useState<boolean>(true);
  const [searchWord, setSearchWord] = useState<string>('');
  const { showBoundary } = useErrorBoundary();
  const [deleteUserFunction, setDeleteUserFunction] = useState<() => void>();
  const [deletePasswordFunction, setDeletePasswordFunction] = useState<() => void>();
  const dispatch = useDispatch();

  const columns: Array<TableColumn<any>> = [
    {
      name: 'ユーザー名',
      sortable: false,
      reorder: false,
      maxWidth: '360px',
      selector: (row) => row.name,
    },
    {
      name: '表示名',
      sortable: false,
      reorder: false,
      maxWidth: '360px',
      selector: (row) => row.displayName,
    },
    {
      name: '',
      sortable: false,
      reorder: false,
      maxWidth: '320px',
      cell: (row) => {
        return row.defaultUser ? (
          <>デフォルトユーザーは更新・削除できません</>
        ) : (
          <div>
            <Button
              variant="primary"
              as="input"
              type="button"
              value="パスワード削除"
              className="m-10"
              disabled={!row.private}
              onClick={() => {
                setDeletePasswordFunction(() => () => deleteRowPassword(row._id));
                setIsDisplayDeletePasswordModal(true);
              }}
            />
            <Button
              variant="danger"
              as="input"
              type="button"
              value="ユーザー削除"
              className="m-10"
              onClick={() => {
                setDeleteUserFunction(() => () => deleteRowUser(row._id));
                setIsDisplayDeleteUserModal(true);
              }}
            />
          </div>
        );
      },
    },
  ];

  const updateUsers = async () => {
    const responseUsers: User[] = await api.getUsers();
    setAllUsers(responseUsers);
    search(searchWord, responseUsers);
  };

  // 各行のパスワード削除ボタンを押下した際の処理
  const deleteRowPassword = async (id: string) => {
    try {
      await api.deletePassword(id);
      await updateUsers();
      toast.success('パスワードを削除しました。', toastConfig());
    } catch (e: any) {
      if (!Axios.isAxiosError(e)) showBoundary(e);
      if (e.response.status === 401 || e.response.status === 403 || e.response.status === 404) {
        toast.error('更新処理でエラーが発生しました。', toastConfig());
      } else {
        showBoundary(e.response);
      }
    } finally {
      setIsDisplayDeletePasswordModal(false);
    }
  };

  // 各行のユーザー削除ボタンを押下した際の処理
  const deleteRowUser = async (id: string) => {
    try {
      await api.deleteUser(id);
      await updateUsers();
      toast.success('ユーザーを削除しました。', toastConfig());
    } catch (e: any) {
      if (!Axios.isAxiosError(e)) showBoundary(e);
      if (e.response.status === 401 || e.response.status === 403 || e.response.status === 404) {
        toast.error('削除処理でエラーが発生しました。', toastConfig());
      } else if (e.response.status === 400) {
        toast.error(
          '削除しようとしているユーザーの作成した利用報告が存在するため、削除することができません。\n該当するレコードを全て削除の上、もう一度お試しください',
          toastNotAutoCloseConfig()
        );
      } else {
        showBoundary(e.response);
      }
    } finally {
      setIsDisplayDeleteUserModal(false);
    }
  };

  const handleSelectedRow = ({ selectedRows }: { selectedRows: any }) => {
    setDisabledBlukButton(!selectedRows.length);
    setSelectedUserIds(selectedRows.map((element) => element._id));
  };

  const clearSelected = () => {
    setToggleCleared();
    setDisabledBlukButton(true);
    setSelectedUserIds([]);
  };

  // 一括パスワード削除ボタンを押下した際の処理
  const deleteCheckedPasswords = async () => {
    try {
      dispatch(startLoading());
      const targetUsers = allUsers.filter((element) => selectedUserIds.includes(element._id));
      const users = await api.bulkDeletePasswords(targetUsers);
      setAllUsers(users);
      search(searchWord, users);
      toast.success('パスワードを削除しました。', toastConfig());
    } catch (e: any) {
      if (!Axios.isAxiosError(e)) showBoundary(e);
      if (e.response.status === 401 || e.response.status === 403 || e.response.status === 404) {
        toast.error('更新処理でエラーが発生しました。', toastConfig());
      } else {
        showBoundary(e.response);
      }
    } finally {
      setIsDisplayDeletePasswordModal(false);
      clearSelected();
      dispatch(stopLoading());
    }
  };

  // 一括ユーザー削除ボタンを押下した際の処理
  const deleteCheckedUsers = async () => {
    try {
      dispatch(startLoading());
      const users = await api.bulkDeleteUsers(selectedUserIds);
      setAllUsers(users);
      search(searchWord, users);
      toast.success('ユーザーを削除しました。', toastConfig());
    } catch (e: any) {
      if (!Axios.isAxiosError(e)) showBoundary(e);
      if (e.response.status === 401 || e.response.status === 403 || e.response.status === 404) {
        toast.error('削除処理でエラーが発生しました。', toastConfig());
      } else if (e.response.status === 400) {
        toast.error(
          '削除しようとしているユーザーの作成した利用報告が存在するため、削除することができません。\n該当するレコードを全て削除の上、もう一度お試しください',
          toastNotAutoCloseConfig()
        );
      } else {
        showBoundary(e.response);
      }
    } finally {
      setIsDisplayDeleteUserModal(false);
      clearSelected();
      dispatch(stopLoading());
    }
  };

  const createUsers = async (parseUsers: User[]) => {
    try {
      dispatch(startLoading());
      const users = await api.bulkCreateUsers(parseUsers);
      setAllUsers(users);
      search(searchWord, users);
      toast.success('ユーザーを追加しました。', toastConfig());
      setIsDisplayCreateUserModal(false);
    } catch (e: any) {
      if (!Axios.isAxiosError(e)) showBoundary(e);
      if (e.response.status === 409) {
        toast.error('ユーザー名もしくは表示名が他のユーザーと重複しています。', toastConfig());
      } else {
        showBoundary(e.response);
      }
    } finally {
      clearSelected();
      dispatch(stopLoading());
    }
  };

  const search = (searchWord: string, baseUsers: User[]) => {
    setDisplayUsers(baseUsers.filter((user) => user.name.includes(searchWord)));
  };

  useEffect(() => {
    search(searchWord, allUsers);
  }, [searchWord]);

  useEffect(() => {
    (async () => {
      await updateUsers();
    })();
  }, []);

  return (
    <>
      <Container>
        <Row className="mt-3">
          <Col>
            <div className="ta-right">
              <Button variant="primary" type="button" className="m-10" onClick={() => setIsDisplayCreateUserModal(true)}>
                <FaPlus style={{ marginRight: '5px', marginBottom: '5px' }} />
                一括作成
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={{ span: 5 }}>
            <div className="ta-left" style={{ marginTop: '20px' }}>
              <Button
                variant="primary"
                as="input"
                type="button"
                value="一括パスワード削除"
                className="m-10"
                disabled={disabledBlukButton}
                onClick={() => {
                  setDeletePasswordFunction(() => deleteCheckedPasswords);
                  setIsDisplayDeletePasswordModal(true);
                }}
              />
              <Button
                variant="danger"
                as="input"
                type="button"
                value="一括ユーザー削除"
                className="m-10"
                disabled={disabledBlukButton}
                onClick={() => {
                  setDeleteUserFunction(() => deleteCheckedUsers);
                  setIsDisplayDeleteUserModal(true);
                }}
              />
            </div>
          </Col>
          <Col md={{ span: 4, offset: 3 }}>
            <Form.Group controlId="searchName">
              <Form.Label>ユーザー名で絞り込み</Form.Label>
              <Form.Control
                type="text"
                onChange={(event) => {
                  setSearchWord(event.target.value);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <GridTable
              columns={columns}
              data={displayUsers}
              selectableRows={true}
              clearSelectedRows={toggleCleared}
              selectableRowDisabled={(row) => row.defaultUser}
              onSelectedRowsChange={handleSelectedRow}
              fontSize="14px"
              autoScroll={false}
              striped={true}
              pagination={false}
            />
          </Col>
        </Row>
      </Container>

      <Modal show={isDisplayDeleteUserModal} onHide={() => setIsDisplayDeleteUserModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>ユーザー削除確認</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            選択したユーザーを削除します。 削除したユーザーは、
            <b className="text-danger">元に戻すことはできません。</b>
          </p>
          <b>削除しようとしているユーザーの作成した利用報告が存在する場合、削除することはできません。</b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsDisplayDeleteUserModal(false)}>
            閉じる
          </Button>
          <Button variant="danger" onClick={deleteUserFunction}>
            ユーザー削除
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isDisplayDeletePasswordModal} onHide={() => setIsDisplayDeletePasswordModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>パスワード削除確認</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>選択したユーザーのパスワードを削除します。</p>
          パスワードを入力せずこのユーザーにログインできるようになるため、
          <b>他のユーザーに利用報告を閲覧されるおそれがあります。</b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsDisplayDeletePasswordModal(false)}>
            閉じる
          </Button>
          <Button variant="danger" onClick={deletePasswordFunction}>
            パスワード削除
          </Button>
        </Modal.Footer>
      </Modal>

      <CreateUsersModal show={isDisplayCreateUserModal} setShow={setIsDisplayCreateUserModal} createUsers={createUsers} />
    </>
  );
};
export default UserManagement;
