import { Col, Table, Card } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from '../../store/loadingReducer';
import { createTitleDict, getStringValue } from '../utils';
import reportApi from '../api/CallCreateReportApi';
import { Report } from '../interface/Report';
import { isReportCreateUserType } from '../interface/ReportCreateUser';
import { useErrorBoundary } from 'react-error-boundary';

interface Props {
  report: Report;
  isDisplayCreatorName?: boolean;
}

const ShowReport: React.FC<Props> = ({ report, isDisplayCreatorName }) => {
  const [mediaTitles, setMediaTitles] = useState<any>({});
  const [workTitles, setWorkTitles] = useState<any>({});
  const dispatch = useDispatch();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    const getData = async () => {
      if (Object.keys(report).length === 0) return;
      try {
        dispatch(startLoading());
        const mediaRegulationId = report.media.regulation?._id;
        if (!mediaRegulationId) throw new Error('媒体のデータ規則が存在しません。');
        const mediaSchema = (await reportApi.getSchemaById(mediaRegulationId)) || {};
        setMediaTitles(createTitleDict(mediaSchema));
        const workRegulationId = report.work.regulation?._id;
        if (!workRegulationId) throw new Error('著作物のデータ規則が存在しません。');
        const workSchema = (await reportApi.getSchemaById(workRegulationId)) || {};
        setWorkTitles(createTitleDict(workSchema));
      } catch (e) {
        showBoundary(e);
      } finally {
        dispatch(stopLoading());
      }
    };
    getData();
  }, [report]);
  return (
    <>
      <Col md={12}>
        <Card className="m-10">
          <Card.Body>
            <Card.Title>基本情報</Card.Title>
            <Table>
              <tbody>
                <tr>
                  <td className="w-25">ステータス</td>
                  <td>{report.phase}</td>
                </tr>
                <tr>
                  <td className="w-25">差戻理由</td>
                  <td className="text-break">{report.comment ? report.comment.content : ''}</td>
                </tr>
                {isDisplayCreatorName && (
                  <tr>
                    <td className="w-25">作成者</td>
                    <td>{isReportCreateUserType(report.user) && report.user.name}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>

        <Card className="m-10">
          <Card.Body>
            <Card.Title>クラス情報</Card.Title>
            <Table>
              <tbody>
                <tr>
                  <td className="w-25">教科、授業科目名等</td>
                  <td className="text-break">{report.classInfo?.subject}</td>
                </tr>
                <tr>
                  <td className="w-25">学年</td>
                  <td>{report.classInfo?.grade.sort((a, b) => a - b).join(',')}年生</td>
                </tr>
                <tr>
                  <td className="w-25">履修者等の人数</td>
                  <td>{report.classInfo?.personCount}</td>
                </tr>
                <tr>
                  <td className="w-25">通信課程の場合は、チェックを入れてください。</td>
                  <td>{getStringValue(report.classInfo?.correspondence)}</td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>

        <Card className="m-10">
          <Card.Body>
            <Card.Title>掲載媒体情報</Card.Title>
            <Table>
              <tbody>
                {report.media &&
                  Object.keys(report.media.info).map((key, index) => (
                    <tr key={index}>
                      <td className="w-25">{mediaTitles[key]}</td>
                      <td className="text-break">{getStringValue(report.media.info[key])}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>

        <Card className="m-10">
          <Card.Body>
            <Card.Title>著作物情報</Card.Title>
            <Table>
              <tbody>
                {report.work &&
                  Object.keys(report.work.info).map((key, index) => (
                    <tr key={index}>
                      <td className="w-25">{workTitles[key]}</td>
                      <td className="text-break">{getStringValue(report.work.info[key])}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default ShowReport;
