import { useEffect, useState } from 'react';
import { WidgetProps } from '@rjsf/utils';
import { useDispatch } from 'react-redux';
import { Button, Form, Col, Alert } from 'react-bootstrap';
import api, { AuthorizedTextbookParams } from '../../api/CallListApi';
import ImprintNumberBox from './ImprintNumberBox';
import TextSymbolBox from './TextSymbolBox';
import TextNumberBox from './TextNumberBox';
import Modal from '../modal/SelectAuthorizedTextBookModal';
import { useErrorBoundary } from 'react-error-boundary';
import { startLoading, stopLoading } from '../../../store/loadingReducer';
import Axios from 'axios';

function InputAuthorizedTextbookWidget(props: WidgetProps) {
  const [imprintNumber, setImprintNumber] = useState<string>('');
  const [imprintName, setImprintName] = useState<string>('');
  const [textSymbol, setTextSymbol] = useState<string>('');
  const [textSymbolList, setTextSymbolList] = useState<string[]>([]);
  const [textNumber, setTextNumber] = useState<string>('');
  const [branchNumber, setBranchNumber] = useState<string>('');
  const [SneLetter, setSneLetter] = useState<string>('');
  const [isSne, setIsSne] = useState<boolean>(false);
  const [authorizedTextbookList, setAuthorizedTextbookList] = useState<any[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { showBoundary } = useErrorBoundary();
  const dispatch = useDispatch();

  const search = async () => {
    if (!isAllEntered()) {
      setErrorMessage('以下の項目全てを入力してから検索ボタンを押してください。');
      return;
    }
    try {
      dispatch(startLoading());
      setErrorMessage('');
      const params: AuthorizedTextbookParams = { imprintNumber, textSymbol, textNumber, branchNumber, SneLetter };
      if (branchNumber) params.branchNumber = branchNumber;
      if (SneLetter && isSne) params.SneLetter = SneLetter;
      const authorizedTextbooks = await api.getAuthorizedTextbook(params);
      if (authorizedTextbooks.length === 1) {
        props.formContext.updateTextBook(authorizedTextbooks[0], imprintName);
      } else if (authorizedTextbooks.length > 1) {
        setAuthorizedTextbookList(authorizedTextbooks);
        setShowModal(true);
      } else {
        setErrorMessage('該当する検定教科書が見つかりませんでした。お手数ですが手入力をお願いします。');
      }
    } catch (e: any) {
      if (!Axios.isAxiosError(e)) showBoundary(e);
      showBoundary(e.response);
    } finally {
      dispatch(stopLoading());
    }
  };

  const isAllEntered = () => {
    const checkNotNullTarget = [imprintNumber, imprintName, textSymbol, textNumber];
    if (isSne) checkNotNullTarget.push(SneLetter);
    return checkNotNullTarget.every((target) => target !== '');
  };

  useEffect(() => {
    setShowAlert(!!errorMessage);
  }, [errorMessage]);

  return (
    <div>
      <Form.Row>
        <Col xs={12} lg={12} xl={12}>
          <Alert variant="danger" show={showAlert}>
            {errorMessage}
          </Alert>
        </Col>
        <Col xs={12} lg={4} xl={3}>
          <ImprintNumberBox
            imprintNumber={imprintNumber}
            setImprintNumber={setImprintNumber}
            imprintName={imprintName}
            setImprintName={setImprintName}
            setErrorMessage={setErrorMessage}
            updateImprintName={(value: string) => props.formContext.updateImprintName(value)}
            api={api}
          />
        </Col>
        <Col xs={12} lg={2} xl={2}>
          <TextSymbolBox
            textSymbolList={textSymbolList}
            setTextSymbolList={setTextSymbolList}
            textSymbol={textSymbol}
            setTextSymbol={setTextSymbol}
            setErrorMessage={setErrorMessage}
            api={api}
          />
        </Col>
        <Col xs={12} lg={6} xl={isSne ? 6 : 5}>
          <TextNumberBox isSne={isSne} setSneLetter={setSneLetter} setTextNumber={setTextNumber} setBranchNumber={setBranchNumber} />
        </Col>
        <Col xs={12} lg={12} xl={1} style={{ alignContent: 'end', textAlign: 'right', marginBottom: '1px' }}>
          <Form.Group>
            <Button variant="primary" type="button" as="input" value="検索" onClick={() => search()} />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Check
            id="isSne"
            type="checkbox"
            label="特別支援学校の教科書"
            as="input"
            checked={isSne}
            onChange={(event) => {
              setIsSne(event.target.checked);
              setSneLetter('');
            }}
          />
        </Col>
      </Form.Row>
      <Modal
        show={showModal}
        setShow={setShowModal}
        itemList={authorizedTextbookList}
        nextStep={(item) => {
          setShowModal(false);
          props.formContext.updateTextBook(item, imprintName);
        }}
      />
    </div>
  );
}

export default InputAuthorizedTextbookWidget;
