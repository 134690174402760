import { Col, Form, Row } from 'react-bootstrap';

interface Props {
  isSne: boolean;
  setSneLetter: (value: string) => void;
  setTextNumber: (value: string) => void;
  setBranchNumber: (value: string) => void;
}

const TextNumberBox: React.FC<Props> = ({ isSne, setSneLetter, setTextNumber, setBranchNumber }) => {
  const separationHyphenStyle = {
    marginTop: '10px',
    marginRight: '-5px',
    marginLeft: '-5px',
    fontSize: '12px',
  };

  return (
    <Form.Group>
      <Form.Label htmlFor="textNumberSpecial_id">教科書番号</Form.Label>
      <Row noGutters>
        {isSne && (
          <>
            <Col xs={2} lg={3} xl={2}>
              <Form.Control type="text" placeholder="例)C" onChange={(event) => setSneLetter(event.target.value)} />
            </Col>
            <Col xs={1} lg={1} xl={1} className="d-flex justify-content-center" style={separationHyphenStyle}>
              <Form.Label>ー</Form.Label>
            </Col>
          </>
        )}
        <Col xs={4} lg={3} xl={isSne ? 4 : 5}>
          <Form.Control type="text" placeholder="例)002" onChange={(event) => setTextNumber(event.target.value)} />
        </Col>
        <Col xs={1} lg={1} xl={1} className="d-flex justify-content-center" style={separationHyphenStyle}>
          <Form.Label>ー</Form.Label>
        </Col>
        <Col xs={4} lg={4} xl={isSne ? 4 : 5}>
          <Form.Control type="text" placeholder="※ある場合" onChange={(event) => setBranchNumber(event.target.value)} />
        </Col>
      </Row>
    </Form.Group>
  );
};

export default TextNumberBox;
