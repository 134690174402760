import { expectedExceptionClient } from './AxiosClientProvider';

const GET_ISBN_API = '/v1/isbn/';
const GET_IMPRINT_NAME_API = '/v1/authorized-textbook/imprintName';
const GET_AUTHORIZED_TEXTBOOK_API = '/v1/authorized-textbook';
const GET_NHK_FOR_SCHOOL_API = '/v1/nhk-for-school';
const GET_AUTHORIZED_TEXT_SYMBOL_API = '/v1/authorized-textbook/textSymbol';
const GET_MOVIE_API = '/v1/movie';

const getIsbn = async (isbn: string): Promise<any> => {
  return await expectedExceptionClient
    .get(GET_ISBN_API + isbn)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

export type AuthorizedTextbookParams = {
  imprintNumber: string;
  textSymbol: string;
  textNumber: string;
  branchNumber?: string;
  SneLetter?: string;
};

const getAuthorizedTextbook = async (params: AuthorizedTextbookParams): Promise<any> => {
  return await expectedExceptionClient
    .get(GET_AUTHORIZED_TEXTBOOK_API, { params })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getImprintName = async (imprintNumber: string): Promise<any> => {
  return await expectedExceptionClient
    .get(GET_IMPRINT_NAME_API, { params: { imprintNumber } })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getNHKForSchool = async (title: string, subTitle: string, subject: string, grade: string, category: string): Promise<any> => {
  return await expectedExceptionClient
    .get(GET_NHK_FOR_SCHOOL_API, {
      params: { title, subTitle, subject, grade, category },
    })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getTextSymbol = async (value: string): Promise<any> => {
  return await expectedExceptionClient
    .get(GET_AUTHORIZED_TEXT_SYMBOL_API, { params: { value } })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getMovie = async (keyword1: string, keyword1Operator: 'contains' | 'startsWith', keyword2?: string): Promise<any> => {
  try {
    const result = await expectedExceptionClient.get(GET_MOVIE_API, { params: { keyword1, keyword1Operator, keyword2 } });
    return result.data;
  } catch (error) {
    throw error;
  }
};

const exportedObject = {
  getIsbn,
  getAuthorizedTextbook,
  getImprintName,
  getNHKForSchool,
  getTextSymbol,
  getMovie,
};

export default exportedObject;
