import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Col, Container, Button, Card } from 'react-bootstrap';
import { Download } from 'react-bootstrap-icons';
import { useErrorBoundary } from 'react-error-boundary';
import { TableColumn } from 'react-data-table-component';
import { toast } from 'react-toastify';
import { toastConfig } from '../utils';
import api from '../api/CallAdminApi';
import ConfirmSubmissionModal from '../components/modal/ConfirmSubmissionModal';
import GridTable from '../components/GridTable';
import { AdminInstitution } from '../interface/AdminInstitution';
import { startLoading, stopLoading } from '../../store/loadingReducer';
import * as XLSX from 'xlsx';

const ShowInstitutionsPage: React.FunctionComponent = () => {
  const [displayAdminInstitutions, setDisplayAdminInstitutions] = useState<AdminInstitution[]>([]);
  const [searchParamTemp, setSearchParamTemp] = useState<any>();
  const [searchType, setSearchType] = useState<'yearMonth' | 'fiscalYear'>('yearMonth');
  const [yearSearchWord, setYearSearchWord] = useState<string>('');
  const [selectMonth, setSelectMonth] = useState<string>('すべて');
  const [fiscalYearSearchWord, setFiscalYearSearchWord] = useState<string>('');
  const [selectOwnerSearch, setSelectOwnerSearch] = useState<string>('すべて');
  const [ownerSearchWord, setOwnerSearchWord] = useState<string>('');
  const [selectInstitutionSearch, setSelectInstitutionSearch] = useState<string>('すべて');
  const [institutionSearchWord, setInstitutionSearchWord] = useState<string>('');
  const [selectedInstitutionId, setSelectedInstitutionId] = useState<any>();
  const [resetPagination, setResetPagination] = useState<any>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { showBoundary } = useErrorBoundary();
  const dispatch = useDispatch();

  const ownerSearchSelectList = ['すべて', '設置者担当者ID', '設置者名', '設置者担当者', '設置者担当者電話番号', '設置者担当者メールアドレス'];
  const institutionSearchSelectList = [
    'すべて',
    '教育機関担当者ID',
    '教育機関名',
    '教育機関担当者',
    '教育機関担当者電話番号',
    '教育機関担当者メールアドレス',
  ];

  const ALL_MONTH = 'すべて';
  const monthSelectList = [ALL_MONTH, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];
  const MIN_WIDTH_ID = '120px';
  const MIN_WIDTH_OTHER = '180px';

  const columns: Array<TableColumn<any>> = [
    {
      name: '年',
      sortable: false,
      wrap: true,
      maxWidth: '10px',
      selector: (row) => row.responsibleTerm?.year ?? ' - ',
    },
    {
      name: '月',
      sortable: false,
      wrap: true,
      maxWidth: '10px',
      selector: (row) => row.responsibleTerm?.month ?? ' - ',
    },
    {
      name: '設置者担当者ID',
      sortable: false,
      wrap: true,
      minWidth: MIN_WIDTH_ID,
      selector: (row) => row.ownerId,
    },
    {
      name: '設置者名',
      sortable: false,
      wrap: true,
      minWidth: MIN_WIDTH_OTHER,
      selector: (row) => row.ownerName,
    },
    {
      name: '設置者担当者',
      sortable: false,
      wrap: true,
      minWidth: MIN_WIDTH_OTHER,
      selector: (row) => row.ownerRepresentativeName,
    },
    {
      name: '設置者担当者電話番号',
      sortable: false,
      wrap: true,
      minWidth: MIN_WIDTH_OTHER,
      selector: (row) => row.ownerPhone,
    },
    {
      name: '設置者担当者メールアドレス',
      sortable: false,
      wrap: true,
      minWidth: MIN_WIDTH_OTHER,
      selector: (row) => row.ownerEmail,
    },
    {
      name: '教育機関担当者ID',
      sortable: false,
      wrap: true,
      minWidth: MIN_WIDTH_ID,
      selector: (row) => row.managerId,
    },
    {
      name: '教育機関名',
      sortable: false,
      wrap: true,
      minWidth: MIN_WIDTH_OTHER,
      selector: (row) => row.institutionName,
    },
    {
      name: '教育機関担当者',
      sortable: false,
      wrap: true,
      minWidth: MIN_WIDTH_OTHER,
      selector: (row) => row.managerName,
    },
    {
      name: '教育機関担当者電話番号',
      sortable: false,
      wrap: true,
      minWidth: MIN_WIDTH_OTHER,
      selector: (row) => row.managerPhone,
    },
    {
      name: '教育機関担当者メールアドレス',
      sortable: false,
      wrap: true,
      minWidth: MIN_WIDTH_OTHER,
      selector: (row) => row.managerEmail,
    },
    {
      name: '報告件数',
      sortable: false,
      wrap: true,
      maxWidth: '50px',
      selector: (row) => (row.isSubmission ? row.reportNumber + '件' : '利用なし'),
    },
    {
      name: '',
      sortable: false,
      reorder: false,
      minWidth: '160px',
      cell: (row) => {
        return (
          <Button
            variant="danger"
            className="m-1 font-small"
            type="button"
            onClick={() => clickSubmission(row.institutionId)}
            disabled={row.reportNumber !== 0 || !row.isSubmission}
          >
            報告対象の利用なし
          </Button>
        );
      },
    },
  ];

  const clickSubmission = async (institutionId: string) => {
    setSelectedInstitutionId(institutionId);
    setShowModal(true);
  };

  const clickDownload = () => {
    const convertDisplayToExportFormat = (displayFormat: any) => {
      const exportFormat: any[] = [];
      for (const row of displayFormat) {
        exportFormat.push({
          年: row.responsibleTerm?.year,
          月: row.responsibleTerm?.month,
          設置者担当者ID: row.ownerId,
          設置者名: row.ownerName,
          設置者担当者: row.ownerRepresentativeName,
          設置者担当者電話番号: row.ownerPhone,
          設置者担当者メールアドレス: row.ownerEmail,
          教育機関担当者ID: row.managerId,
          教育機関名: row.institutionName,
          教育機関担当者: row.managerName,
          教育機関担当者電話番号: row.managerPhone,
          教育機関担当者メールアドレス: row.managerEmail,
          報告件数: row.reportNumber,
          利用なしフラグ: Number(!row.isSubmission),
        });
      }
      return exportFormat;
    };
    try {
      const now = new Date();
      const timestamp =
        now.getFullYear().toString() +
        (now.getMonth() + 1).toString().padStart(2, '0') +
        now.getDate().toString().padStart(2, '0') +
        now.getHours().toString().padStart(2, '0') +
        now.getMinutes().toString().padStart(2, '0');
      const filename = `${timestamp}_${selectMonth}_${ownerSearchWord}_${institutionSearchWord}_教育機関一覧.xlsx`;
      const worksheet = XLSX.utils.json_to_sheet(convertDisplayToExportFormat(displayAdminInstitutions));
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelFormat = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
      const blob = new Blob([excelFormat], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (e: any) {
      showBoundary(e.response);
    }
  };

  const generateSearchParams = () => {
    const params: any = {};

    if (searchType === 'yearMonth') {
      if (yearSearchWord !== '') params.year = yearSearchWord;
      if (selectMonth !== ALL_MONTH) params.month = selectMonth;
    } else {
      if (fiscalYearSearchWord !== '') params.fiscalYear = fiscalYearSearchWord;
    }

    if (selectOwnerSearch === '設置者担当者ID') params.ownerId = ownerSearchWord;
    if (selectOwnerSearch === '設置者名') params.ownerName = ownerSearchWord;
    if (selectOwnerSearch === '設置者担当者') params.ownerRepresentativeName = ownerSearchWord;
    if (selectOwnerSearch === '設置者担当者電話番号') params.ownerPhone = ownerSearchWord;
    if (selectOwnerSearch === '設置者担当者メールアドレス') params.ownerEmail = ownerSearchWord;
    if (selectInstitutionSearch === '教育機関担当者ID') params.managerId = institutionSearchWord;
    if (selectInstitutionSearch === '教育機関名') params.institutionName = institutionSearchWord;
    if (selectInstitutionSearch === '教育機関担当者') params.managerName = institutionSearchWord;
    if (selectInstitutionSearch === '教育機関担当者電話番号') params.managerPhone = institutionSearchWord;
    if (selectInstitutionSearch === '教育機関担当者メールアドレス') params.managerEmail = institutionSearchWord;
    return params;
  };

  const displayFilteredManagers = (searchParam: any) => {
    (async () => {
      setResetPagination(true);
      dispatch(startLoading());
      const adminInstitutions = await api.managerDetailedSearch(searchParam);
      if (!adminInstitutions.length) toast.error('条件に当てはまるデータはありませんでした。', toastConfig());
      setDisplayAdminInstitutions(adminInstitutions);
      setSearchParamTemp(searchParam);
      setResetPagination(false);
      dispatch(stopLoading());
    })();
  };

  return (
    <Container>
      <Row>
        <Col md={6}>
          <Card className="mb-3">
            <Card.Body>
              <Form.Check
                id="yearMonth"
                type="radio"
                label="年月で検索"
                name="searchType"
                checked={searchType === 'yearMonth'}
                onChange={() => setSearchType('yearMonth')}
                className="mb-2"
              />
              <div
                style={{
                  opacity: searchType === 'yearMonth' ? 1 : 0.5,
                  pointerEvents: searchType === 'yearMonth' ? 'auto' : 'none',
                }}
              >
                <Row className="mb-2">
                  <Form.Label column md="3">
                    対象年：
                  </Form.Label>
                  <Col md="9">
                    <Form.Control type="text" value={yearSearchWord} onChange={(e) => setYearSearchWord(e.target.value)} />
                  </Col>
                </Row>
                <Row>
                  <Form.Label column md="3">
                    対象月：
                  </Form.Label>
                  <Col md="9">
                    <Form.Control as="select" value={selectMonth} onChange={(e) => setSelectMonth(e.target.value)}>
                      {monthSelectList.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="mb-3">
            <Card.Body>
              <Form.Check
                id="fiscalYear"
                type="radio"
                label="年度で検索"
                name="searchType"
                checked={searchType === 'fiscalYear'}
                onChange={() => setSearchType('fiscalYear')}
                className="mb-2"
              />
              <div
                style={{
                  opacity: searchType === 'fiscalYear' ? 1 : 0.5,
                  pointerEvents: searchType === 'fiscalYear' ? 'auto' : 'none',
                }}
              >
                <Row>
                  <Form.Label column md="4">
                    対象年度：
                  </Form.Label>
                  <Col md="8">
                    <Form.Control type="text" value={fiscalYearSearchWord} onChange={(e) => setFiscalYearSearchWord(e.target.value)} />
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row style={{ marginBottom: '5px' }}>
        <Form.Label column md="2" className="filter-label pr-0">
          設置者の検索：
        </Form.Label>
        <Col md="3" className="pl-0">
          <Form.Control
            as="select"
            value={selectOwnerSearch}
            onChange={(e) => {
              if (e.target.value === ownerSearchSelectList[0]) setOwnerSearchWord('');
              setSelectOwnerSearch(e.target.value);
            }}
          >
            {ownerSearchSelectList.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </Form.Control>
        </Col>
        <Col md="3" className="pl-0">
          <Form.Control
            type="text"
            value={ownerSearchWord}
            onChange={(e) => setOwnerSearchWord(e.target.value)}
            disabled={selectOwnerSearch === ownerSearchSelectList[0]}
          ></Form.Control>
        </Col>
      </Row>
      <Row style={{ marginBottom: '5px' }}>
        <Form.Label column md="2" className="filter-label pr-0">
          教育機関の検索：
        </Form.Label>
        <Col md="3" className="pl-0">
          <Form.Control
            as="select"
            value={selectInstitutionSearch}
            onChange={(e) => {
              if (e.target.value === institutionSearchSelectList[0]) setInstitutionSearchWord('');
              setSelectInstitutionSearch(e.target.value);
            }}
          >
            {institutionSearchSelectList.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </Form.Control>
        </Col>
        <Col md="3" className="pl-0">
          <Form.Control
            type="text"
            value={institutionSearchWord}
            onChange={(e) => setInstitutionSearchWord(e.target.value)}
            disabled={selectInstitutionSearch === institutionSearchSelectList[0]}
          ></Form.Control>
        </Col>
        <Col md="2">
          <div className="ta-left" style={{ marginTop: '-10px' }}>
            <Button variant="primary" type="button" className="m-10" onClick={() => displayFilteredManagers(generateSearchParams())}>
              表示
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <GridTable columns={columns} data={displayAdminInstitutions} fontSize="12px" resetPagination={resetPagination} />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 12 }} lg={{ span: 6 }}>
          <div className="d-flex">
            <div className="mr-1">
              <Button variant="primary" className="w-200" onClick={clickDownload}>
                <Download size={20} />
                Excelファイルで出力
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <ConfirmSubmissionModal
        show={showModal}
        setShow={setShowModal}
        institutionId={selectedInstitutionId}
        updateDisplayFunc={() => displayFilteredManagers(searchParamTemp)}
        markAsNoSubmissionAPI={api.markAsNoSubmission}
      />
    </Container>
  );
};

export default ShowInstitutionsPage;
