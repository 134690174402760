import { Col, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useErrorBoundary } from 'react-error-boundary';
import { startLoading, stopLoading } from '../../../store/loadingReducer';
import Axios from 'axios';

interface Props {
  imprintNumber: string;
  setImprintNumber: (value: string) => void;
  imprintName: string;
  setImprintName: (value: string) => void;
  setErrorMessage: (value: string) => void;
  updateImprintName: (value: string) => void;
  api: any;
}

const ImprintNumberBox: React.FC<Props> = ({
  imprintNumber,
  setImprintNumber,
  imprintName,
  setImprintName,
  setErrorMessage,
  updateImprintName,
  api,
}) => {
  const dispatch = useDispatch();
  const { showBoundary } = useErrorBoundary();

  const searchImprintName = async () => {
    try {
      dispatch(startLoading());
      setErrorMessage('');
      const result = await api.getImprintName(imprintNumber);
      setImprintName(result.imprintNameAbbr);
      updateImprintName(result.imprintName);
    } catch (e: any) {
      if (!Axios.isAxiosError(e)) showBoundary(e);
      if (e.response.status === 404) {
        setImprintName('');
        setErrorMessage('存在しない発行者番号です。番号を確認して再度入力してください。');
      } else {
        showBoundary(e.response);
      }
    } finally {
      dispatch(stopLoading());
    }
  };

  return (
    <Form.Group>
      <Form.Label htmlFor="imprintNumber_id">発行者番号</Form.Label>
      <Form.Row>
        <Col xs={6} lg={4} xl={4}>
          <Form.Control
            type="text"
            placeholder="例)2"
            onChange={(event) => setImprintNumber(event.target.value)}
            onBlur={() => searchImprintName()}
          />
        </Col>
        <Col xs={6} lg={8} xl={8}>
          <Form.Control
            type="text"
            placeholder="例)東書"
            readOnly={true}
            onChange={(event) => setImprintName(event.target.value)}
            value={imprintName}
          />
        </Col>
      </Form.Row>
    </Form.Group>
  );
};

export default ImprintNumberBox;
