import { RJSFSchema, UiSchema } from '@rjsf/utils';
import { customizeValidator } from '@rjsf/validator-ajv8';
import Form from '@rjsf/bootstrap-4';
import { Ref, forwardRef, useState, useEffect, useCallback } from 'react';
import localizer from 'ajv-i18n';
import { getErrorMessage } from '../utils';

interface apiDataProps {
  schema: any;
  uiSchema: any;
  isValidationOff: boolean;
  formData: any;
  setFormData: (formData: any, type: string) => void;
}

const InputForm = forwardRef<HTMLButtonElement, apiDataProps>((props, ref: Ref<HTMLButtonElement>) => {
  const [formData, setFormData] = useState<any>({});
  const uiSchema: UiSchema = props.uiSchema;
  const schema: RJSFSchema = props.schema;
  const validator = customizeValidator({}, localizer.ja);

  const keydownEnter = useCallback((event: any) => {
    if (event && event.keyCode === 13) {
      event.preventDefault();
    }
  }, []);

  useEffect(() => {
    setFormData(props.formData);
  }, [props.formData]);

  useEffect(() => {
    document.addEventListener('keydown', keydownEnter, false);
    return () => {
      document.removeEventListener('keydown', keydownEnter);
    };
  }, []);

  const preventNumberChange = (event: any) => {
    if ((document.activeElement as HTMLInputElement)?.type === 'number') {
      event.target.blur();
    }
  };

  useEffect(() => {
    document.addEventListener('wheel', preventNumberChange, false);
    return () => {
      document.removeEventListener('wheel', preventNumberChange);
    };
  }, []);

  const onsubmit = (formData: any): void => {
    props.setFormData(formData, 'submit');
  };

  const nfsChangeDetector = (formData: object, event: any) => {
    // NOTE: https://crosswarp-ip.atlassian.net/browse/SAR-488
    if ('NFS_ID' in formData && !['root_used_part', 'root_used_time'].includes(event)) {
      formData.NFS_ID = '';
    }
    return formData;
  };

  const changeForm = (formData: any): void => {
    setFormData(formData);
    props.setFormData(formData, 'change');
  };

  const updateIsbnData = (data: any, isbnCode: string) => {
    if (data.language === '日本語') {
      setFormData((prev) => ({
        ...prev,
        isbn: isbnCode || undefined,
        title: data.title || undefined,
        publisher_name: data.imprint || undefined,
        publish_date: data.publishingDate || undefined,
        author_name: data.contributors || undefined,
        language: data.language || undefined,
        use_japanese_translator: data.useTranslator || undefined,
        japanese_translator: data.translators || undefined,
      }));
    } else if (data.language === '外国語') {
      setFormData((prev) => ({
        ...prev,
        isbn: isbnCode || undefined,
        title: data.title || undefined,
        publisher_name: data.imprint || undefined,
        publish_date: data.publishingDate || undefined,
        author_name: data.contributors || undefined,
        language: data.language || undefined,
        use_foreign_translator: data.useTranslator || undefined,
        foreign_translator: data.translators || undefined,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        isbn: isbnCode || undefined,
        title: data.title || undefined,
        publisher_name: data.imprint || undefined,
        publish_date: data.publishingDate || undefined,
        author_name: data.contributors || undefined,
      }));
    }
  };

  const updateTextBook = (data: any, imprintName: string) => {
    setFormData((prev) => ({
      ...prev,
      approval_year: data.certificateYear || undefined,
      grade: data.grade || undefined,
      textbook_name: data.title || undefined,
      school_type: data.schoolType || undefined,
      subject: data.subject || undefined,
      number:
        `${data.imprintNumber}${imprintName}${data.textSymbol}${data.SneLetter ? `${data.SneLetter}-` : ''}${data.textNumber}${data.branchNumber ? `-${data.branchNumber}` : ''}` ||
        undefined,
    }));
  };

  const updateImprintName = (name: string) => {
    setFormData((prev) => ({ ...prev, publisher_name: name || undefined }));
  };

  const updateNHKForSchool = (data: any) => {
    setFormData((prev) => ({
      ...prev,
      title: data.title || undefined,
      subtitle: data.subTitle || undefined,
      grade: data.grade || undefined,
      subject: data.subject || undefined,
      type: data.category || undefined,
      url: '検索結果自動反映のため入力省略',
      NFS_ID: data._id || undefined,
    }));
  };

  const updateMovie = (data: any) => {
    setFormData((prev) => ({
      ...prev,
      movie_title: `${data.syohinTitle} ${data.trackTitle}` || undefined,
      broadcaster_name: data.seisaku || '不明',
      translator: data.honyaku || undefined,
      product_number: data.hinban || undefined,
      original_author: data.gensaku || undefined,
      scriptwriter: data.kyakuhon || undefined,
    }));
  };

  const updateMediaMusic = (data: any) => {
    setFormData((prev: any) => ({
      ...prev,
      record_number: data.syohinno,
      album_single_title: data.title || '不明',
      song_title: data.kyoku || '不明',
      artist_name: data.artist || '不明',
      track_number: data.trackno,
      lyricist_name: data.lyricist_name,
      composer_name: data.composer_name,
      arranger_name: data.arranger_name,
      disc_number: data.discno,
      isrc: data.isrc,
      jasrac_code: data.jas_sakucd,
      nextone_code: data.nt_sakucd,
    }));
  };

  const transformErrors = (errors: any, uiSchema: any) => {
    return errors.map((error: any) => {
      const key = error.property.replace('.', '');
      const title = uiSchema[key]['ui:title'];
      const errorMessages = getErrorMessage(error, title);
      error.message = errorMessages.message;
      error.stack = errorMessages.stack;
      return error;
    });
  };

  return (
    <div>
      <Form
        formContext={{
          updateIsbnData,
          updateTextBook,
          updateImprintName,
          updateNHKForSchool,
          updateMovie,
          updateMediaMusic,
        }}
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        onChange={({ formData }, e) => {
          changeForm(nfsChangeDetector(formData, e));
        }}
        onSubmit={({ formData }, e) => {
          onsubmit(formData);
        }}
        omitExtraData={true}
        noHtml5Validate={true}
        noValidate={props.isValidationOff}
        validator={validator}
        transformErrors={transformErrors}
      >
        <button ref={ref} type="submit" style={{ display: 'none' }} />
      </Form>
    </div>
  );
});

export default InputForm;
